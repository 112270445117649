<template>
  <div>
    <Navbar />

    <div class="container text-center Top">
      <h2>Contact Us</h2>
      <p>
        We're ready to lead you into the future of Vaccine Tracking Systems.
      </p>

      <div class="row mt-5">
        <div class="col-lg-6">
          <div class="row h-100">
            <div class="col-md-12 mb-4 card p-5">
              <div class="info-box">
                <i class="bx bx-map bx-md"></i>
                <h3>Our Address</h3>
                <p>
                  <strong>JAB COMPANIES, INC</strong> <br />1405 Silver Lake
                  Road NW, St. Paul, MN 55112
                </p>
              </div>
            </div>
            <div class="row m-0 w-100 d-flex justify-content-around">
              <div class="col-md-5 mb-4 card p-4">
                <div class="info-box">
                  <i class="bx bx-envelope bx-md"></i>
                  <h3>Email Us</h3>
                  <p>
                    <a href="mailto:info@vaxtraxglobal.com"
                      >info@vaxtraxglobal.com</a
                    >
                  </p>
                </div>
              </div>
              <div class="col-md-5 mb-4 p-4 card">
                <div class="info-box">
                  <i class="bx bx-phone-call bx-md"></i>
                  <h3>Call Us</h3>
                  <p>
                    <strong>PH:</strong>
                    <a href="tel:16123380794">+1 612-338-0794</a><br />
                    <strong>MB:</strong>
                    <a href="tel:16125008805">+1 612-500-8805</a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6 mb-4">
          <form class="h-100 card p-5">
            <div class="form-row mt-5">
              <div class="col-md-6 form-group">
                <input
                  type="text"
                  name="name"
                  class="form-control"
                  id="name"
                  placeholder="Your Name"
                  data-rule="minlen:4"
                  data-msg="Please enter at least 4 chars"
                />
                <div class="validate"></div>
              </div>
              <div class="col-md-6 form-group">
                <input
                  type="email"
                  class="form-control"
                  name="email"
                  id="email"
                  placeholder="Your Email"
                  data-rule="email"
                  data-msg="Please enter a valid email"
                />
                <div class="validate"></div>
              </div>
            </div>
            <div class="form-group">
              <input
                type="text"
                class="form-control"
                name="subject"
                id="subject"
                placeholder="Subject"
                data-rule="minlen:4"
                data-msg="Please enter at least 8 chars of subject"
              />
              <div class="validate"></div>
            </div>
            <div class="form-group">
              <textarea
                class="form-control"
                name="message"
                rows="5"
                data-rule="required"
                data-msg="Please write something for us"
                placeholder="Message"
              ></textarea>
              <div class="validate"></div>
            </div>
            <!-- <div class="mb-3">
              <div class="loading">Loading</div>
              <div class="error-message"></div>
              <div class="sent-message">
                Your message has been sent. Thank you!
              </div>
            </div> -->
            <div class="text-center">
              <button class="btn btn-New" type="submit">Send Message</button>
            </div>
          </form>
        </div>
      </div>
      <div class="container-fluid"></div>
    </div>
    <!-- <Footer /> -->
  </div>
</template>

<script>
import { Loader } from "@googlemaps/js-api-loader";
import Navbar from "../../components/Navbar.vue";
import { Icon } from "@iconify/vue2";

require("../../assets/close.css");
export default {
  components: {
    Navbar,
    Icon,
  },
  mounted() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  },
};
</script>

<style scoped>
body {
  background-color: rgb(241, 238, 238);
}
.Top {
  margin-top: 15vh;
}
.btn-New {
  padding: 13px;
  width: 200px;
  background-color: rgb(85, 85, 250);
  color: #fff;
  border-radius: 25px;
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: bolder;
}

h2 {
  font-weight: 700;
  margin-bottom: 20px;
  padding-bottom: 20px;
  position: relative;
  color: #06629d;
  text-transform: uppercase;
}
h2:before {
  content: "";
  position: absolute;
  width: 50px;
  height: 6px;
  background-color: #e88810;
  left: 0;
  right: 0;
  bottom: -3px;
  border-radius: 5px;
  margin: 0 auto;
}
.bx-md {
  color: rgb(214, 142, 8);
  border-radius: 50%;
  padding: 5px;
  border: 2px dotted #ffd09c;
}

.card {
  border-radius: 20px;
  box-shadow: 0 0 30px rgb(214 215 216 / 60%);
}

textarea {
  resize: none;
}
</style>
